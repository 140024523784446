/*
	* This is for when we need to require an asset with a variable name.  IE looping through something.
	* If you end up with the warning icon (and you were expecting it) then the icon you want is
*/

import { logError } from '@/utils/error-handling.js'

const requireIcon = function (icon) {
	if (!icon) return
	try {
		return require(`@/assets/icons/${icon}`)
	} catch (e) {
		logError(e, { extraData: `/assets/icons/${icon} missing` })
		return require('@/assets/icons/warning.svg')
	}
}

export default requireIcon
